<!--Email Delivery Log Details-->
<ejs-dialog
  id="cdi-email-delivery-log-details-dialog"
  #dialogEmailDeliveryLogDetails
  header="Email Delivery Log Details"
  [showCloseIcon]="true"
  [visible]="false"
  [isModal]="true"
  [enableResize]="true"
  (close)="hide()"
>
  <ng-template #content>
    <ejs-grid
      #gridEmailDeliveryLogDetails
      id="email-delivery-log-details"
      [allowSorting]="true"
      allowTextWrap="true"
      [allowResizing]="true"
      [dataSource]="emailDeliveryLogDetails"
      [toolbar]="toolbarEmailDeliveryLogGrid"
    >
      <e-columns>
        <e-column
          headerText="Log Id"
          field="id"
          [allowEditing]="false"
          type="string"
          width="100px"
        >
        </e-column>
        <e-column
          headerText="Job Id"
          field="notificationServiceJobId"
          type="string"
          width="100px"
          [visible]="false"
        >
        </e-column>
        <e-column
          headerText="Message Type"
          field="messageType"
          [allowEditing]="false"
          type="string"
          width="100px"
        ></e-column>
        <e-column
          headerText="Order Number"
          field="orderNumber"
          [allowEditing]="false"
          type="string"
          width="100px"
        >
        </e-column>
        <e-column
          headerText="Datestamp"
          field="datestamp"
          [allowEditing]="false"
          type="date"
          [format]="dateFormatOption"
          width="100px"
        ></e-column>
        <e-column
          headerText="Log Message"
          textAlign="left"
          field="logMessage"
          type="string"
          width="200px"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </ng-template> </ejs-dialog
><!--show Email Address-->
<ejs-dialog
  id="cdi-email-address-dialog"
  #dialogEmailAddress
  header="Email Address"
  [showCloseIcon]="true"
  [visible]="false"
  [isModal]="true"
  [enableResize]="true"
  (close)="hide()"
  width="30vw"
>
  <ng-template #content>
    <ejs-listbox
      id="cdi-email-address-list"
      [dataSource]="emailAddressList"
      [fields]="{ text: 'text' }"
    ></ejs-listbox>
  </ng-template>
</ejs-dialog>
<!-- Email Delivery Log Gird-->
<div class="e-card">
  <div
    class="e-card-title"
    style="
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    "
  >
    <div style="display: flex; align-items: center">
      <div style="margin-right: 20px">Email Delivery</div>
    </div>
  </div>
  <div class="e-card-separator"></div>
  <div class="e-card-content">
    <div style="height: 400px; width: 100%">
      <ejs-grid
        id="cdi-email-delivery-log-grid"
        #emailDeliveryLogGrid
        [dataSource]="emailDeliveryLogList"
        height="100%"
        width="100%"
        enableStickyHeader="true"
        [allowResizing]="true"
        gridLines="Default"
        [allowSorting]="true"
        [allowFiltering]="true"
        [allowPaging]="false"
        allowTextWrap="true"
        [filterSettings]="filterOption"
        [loadingIndicator]="loadingIndicator"
        [allowPdfExport]="true"
        [allowExcelExport]="true"
        [allowResizing]="true"
        [toolbar]="toolbarEmailDeliveryLogGrid"
        [enableHover]="enableRowHover"
        [showColumnChooser]="true"
        (rowSelected)="onEmailDeliveryLogGridRowSelected($event.data)"
      >
        <e-columns>
          <e-column
            field="id"
            headerText="Id"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>

          <e-column
            field="dateCreated"
            headerText="Date Created"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
            type="date"
            [format]="dateFormatOption"
            [allowFiltering]="false"
          ></e-column>
          <e-column
            field="orderNumber"
            headerText="Order Number"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="companyCode"
            headerText="Company Code"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
            [visible]="false"
          ></e-column>
          <e-column
            field="sbuCode"
            headerText="SBU Code"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="messageId"
            headerText="Message Id"
            textAlign="Center"
            width="120"
          ></e-column>
          <e-column
            field="failedAttempts"
            headerText="Failed Attempts"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column
            field="documentType"
            headerText="Order Type"
            textAlign="Center"
            width="120"
            clipMode="EllipsisWithTooltip"
          ></e-column>
          <e-column headerText="Action" maxWidth="130">
            <ng-template #template let-data>
              <ejs-menu
                target="#target"
                [items]="menuItems"
                showItemOnClick="true"
                (select)="onEmailDeliveryContextMenuSelect($event)"
              ></ejs-menu> </ng-template
          ></e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</div>
