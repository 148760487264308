<!-- Add Order Minimum Dialog -->
<ejs-dialog
  id="cdi-add-order-minimum-ejDialog"
  #ejDialogAddOrderMinimum
  [showCloseIcon]="true"
  header="Add Order Minimum"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddOrderMinimumDialogCloselButtonClick()"
>
  <form [formGroup]="addOrderMinimumForm">
    <div>
      <label for="currencyCode">Currency Code: </label>
      <input
        id="currencyCode"
        class="e-input"
        type="text"
        placeholder="Enter currencyCode"
        formControlName="currencyCode"
      />
      <div
        *ngIf="
          currencyCode?.invalid &&
          (currencyCode?.dirty || currencyCode?.touched)
        "
        class="form-error"
      >
        <div *ngIf="currencyCode?.errors?.['required']">
          * Currency Code is required.
        </div>
        <div *ngIf="currencyCode?.errors?.['minlength']">
          * Currency Code must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="countryCode">Country Code: </label>
      <div
        style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
      >
        <div class="e-input-group" style="order: 1">
          <input
            id="countryCode"
            class="e-input"
            type="text"
            placeholder="Enter Country Code"
            formControlName="countryCode"
          />
        </div>
      </div>

      <div
        *ngIf="
          countryCode?.invalid && (countryCode?.dirty || countryCode?.touched)
        "
        class="form-error"
      >
        <div *ngIf="countryCode?.errors?.['required']">
          * Country Code is required.
        </div>
        <div *ngIf="countryCode?.errors?.['minlength']">
          * Country Code must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="orderMinimumAmount">Minimum Amount: </label>
      <input
        id="orderMinimumAmount"
        class="e-input"
        type="text"
        placeholder="Enter Minimum Amount"
        formControlName="orderMinimumAmount"
      />
      <div
        *ngIf="
          orderMinimumAmount?.invalid &&
          (orderMinimumAmount?.dirty || orderMinimumAmount?.touched)
        "
        class="form-error"
      >
        <div *ngIf="orderMinimumAmount?.errors?.['required']">
          * Minimum Amount is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="handlingCharge">Handling Charge: </label>
      <input
        id="handlingCharge"
        class="e-input"
        type="text"
        placeholder="Enter Handling Charge"
        formControlName="handlingCharge"
      />
      <div
        *ngIf="
          handlingCharge?.invalid &&
          (handlingCharge?.dirty || handlingCharge?.touched)
        "
        class="form-error"
      >
        <div *ngIf="handlingCharge?.errors?.['required']">
          * Minimum Amount is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="false"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>

    <br /><br />
    <button
      id="cdi-add-order-minimumn-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      [disabled]="!addOrderMinimumForm.valid"
      (click)="onSaveAddOrderMinimumButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-add-order-minimumn-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddOrderMinimumDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>
<!-- Edit Order Minimum Dialog -->
<ejs-dialog
  id="cdi-edit-order-minimum-ejDialog"
  #ejDialogEditOrderMinimum
  [showCloseIcon]="true"
  header="Edit Order Minimum"
  isModal="true"
  [closeOnEscape]="false"
  [visible]="false"
  width="50vw"
  (close)="onAddOrderMinimumDialogCloselButtonClick()"
>
  <form [formGroup]="addOrderMinimumForm">
    <div>
      <label for="currencyCode">Currency Code: </label>
      <input
        id="currencyCode"
        class="e-input"
        type="text"
        placeholder="Enter currencyCode"
        formControlName="currencyCode"
        [value]="editOrderMinimumData?.currencyCode"
      />
      <div
        *ngIf="
          currencyCode?.invalid &&
          (currencyCode?.dirty || currencyCode?.touched)
        "
        class="form-error"
      >
        <div *ngIf="currencyCode?.errors?.['required']">
          * Currency Code is required.
        </div>
        <div *ngIf="currencyCode?.errors?.['minlength']">
          * Currency Code must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="countryCode">Country Code: </label>
      <div
        style="display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px"
      >
        <div class="e-input-group" style="order: 1">
          <input
            id="countryCode"
            class="e-input"
            type="text"
            placeholder="Enter Country Code"
            formControlName="countryCode"
            [value]="editOrderMinimumData?.countryCode"
          />
        </div>
      </div>
      <div
        *ngIf="
          countryCode?.invalid && (countryCode?.dirty || countryCode?.touched)
        "
        class="form-error"
      >
        <div *ngIf="countryCode?.errors?.['required']">
          * Country Code is required.
        </div>
        <div *ngIf="countryCode?.errors?.['minlength']">
          * Country Code must be at least 3 characters long.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="orderMinimumAmount">Minimum Amount: </label>
      <input
        id="orderMinimumAmount"
        class="e-input"
        type="text"
        placeholder="Enter Minimum Amount"
        formControlName="orderMinimumAmount"
        [value]="editOrderMinimumData?.orderMinimumAmount"
      />
      <div
        *ngIf="
          orderMinimumAmount?.invalid &&
          (orderMinimumAmount?.dirty || orderMinimumAmount?.touched)
        "
        class="form-error"
      >
        <div *ngIf="orderMinimumAmount?.errors?.['required']">
          * Minimum Amount is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div>
      <label for="handlingCharge">Handling Charge: </label>
      <input
        id="handlingCharge"
        class="e-input"
        type="text"
        placeholder="Enter Handling Charge"
        formControlName="handlingCharge"
        [value]="editOrderMinimumData?.handlingCharge"
      />
      <div
        *ngIf="
          handlingCharge?.invalid &&
          (handlingCharge?.dirty || handlingCharge?.touched)
        "
        class="form-error"
      >
        <div *ngIf="handlingCharge?.errors?.['required']">
          * Minimum Amount is required.
        </div>
      </div>
    </div>
    <br /><br />
    <div style="order: 1; flex: 10; display: flex; align-items: center">
      <label for="isActiveValue" style="margin-right: 10px">Is Active:</label>

      <div style="flex: 1">
        <ejs-switch
          id="change-userstatus-switch"
          #userStatusSwitch
          [checked]="editOrderMinimumData?.isActive"
          (change)="onUserStatusSwitchIsActiveToggle($event)"
        ></ejs-switch>
      </div>
    </div>
    <br /><br />
    <button
      id="cdi-edit-order-minimum-save-button"
      class="e-control e-btn e-success sendButton"
      data-ripple="true"
      (click)="onSaveEditOrderMinimumButtonClick()"
    >
      Save
    </button>
    <button
      id="cdi-edit-order-minimum-cancel-button"
      class="e-control e-btn e-danger"
      data-ripple="true"
      (click)="onAddOrderMinimumDialogCloselButtonClick()"
    >
      Cancel
    </button>
  </form>
</ejs-dialog>
<!-- Order Minimum Gird-->

<div
  class="e-card-title"
  style="
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    font-size: 18px;
    line-height: 12px;
    padding: 8px;
  "
>
  <div>Order Minimum</div>
  <div style="display: flex; gap: 10px">
    <button
      id="cdi-add-order-minimum-button"
      ejs-button
      style="background-color: #00308f"
      (click)="onClickAddOrderMinimumButton()"
    >
      Add
    </button>
  </div>
</div>
<div class="e-card-separator"></div>
<div class="e-card-content">
  <div style="height: 400px; width: 100%">
    <ejs-grid
      id="cdi-order-minimum-grid"
      #orderMinimumGrid
      (actionBegin)="onOrderMinimumGridActionBegin($event)"
      [dataSource]="orderMinimumList"
      [allowPaging]="false"
      [allowSorting]="true"
      allowTextWrap="true"
      [allowFiltering]="true"
      [filterSettings]="filterOption"
      [loadingIndicator]="loadingIndicator"
      width="100%"
      height="100%"
      [toolbar]="toolbarOrderMinimumGrid"
      [allowPdfExport]="true"
      [allowExcelExport]="true"
      [allowResizing]="true"
      [showColumnChooser]="true"
      [enableHover]="enableRowHover"
      (rowSelected)="onOrderMinimumGridRowSelected($event.data)"
    >
      <e-columns>
        <e-column
          field="dateCreated"
          headerText="Date Created"
          textAlign="Center"
          width="130"
          type="date"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="false"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column
          field="companyCode"
          headerText="Company Code"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="currencyCode"
          headerText="Currency Code"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="countryCode"
          headerText="Country Code"
          textAlign="Center"
          width="120"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="orderMinimumAmount"
          headerText="Minimum Amount"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          field="handlingCharge"
          headerText="Handling Charge"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
        ></e-column>
        <e-column
          id="isActive"
          field="isActive"
          headerText="Is Active"
          textAlign="Center"
          width="100"
          [allowFiltering]="false"
          ><ng-template #template let-data>
            <ejs-switch
              id="change-userstatus-switch"
              #userStatusSwitch
              (change)="
                onChangeUserStatusSwitchClick($event, userStatusSwitch, data)
              "
              [checked]="data.isActive"
            ></ejs-switch> </ng-template></e-column
        ><e-column
          field="dateModified"
          headerText="Date Modified"
          textAlign="Center"
          width="130"
          type="date"
          [format]="dateFormatOption"
          clipMode="EllipsisWithTooltip"
          [allowFiltering]="false"
          [visible]="false"
        ></e-column>
        <e-column
          field="createdOrModifiedBy"
          headerText="Created Or Modified By"
          textAlign="Center"
          width="130"
          clipMode="EllipsisWithTooltip"
          [visible]="false"
        ></e-column>
        <e-column headerText="Action" maxWidth="120">
          <ng-template #template let-data
            ><ejs-menu
              target="#target"
              [items]="menuItems"
              showItemOnClick="true"
              (select)="onOrderMinimumContextMenuSelect($event)"
            ></ejs-menu> </ng-template
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
