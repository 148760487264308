import { QueuedLineItem } from './queued-line-item.model';
import { QueuedTaxLine } from './queued-tax-lines.model';

export class QueuedOrder {
  public id?: number;
  public orderType?: number;
  public jdeOrderNumber?: number;
  public shopifyOrderId?: number;
  public orderNumber?: string;
  public firstName?: string;
  public lastName?: string;
  public phone?: string;
  public shippingAddress?: string;
  public shippingCity?: string;
  public shippingState?: string;
  public shippingCountry?: string;
  public shippingZip?: string;
  public billingAddress?: string;
  public billingCity?: string;
  public billingState?: string;
  public billingCountry?: string;
  public billingZip?: string;
  public lineItems?: QueuedLineItem[];
  public taxLines?: QueuedTaxLine[];
  public currency?: string;
  public shippingDescription?: string;
  public shippingPrice?: string;
}
