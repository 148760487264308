export class OrderMinimum {
  public id?: number;
  public dateCreated?: Date;
  public companyCode?: string;
  public currencyCode?: string;
  public countryCode?: string;
  public orderMinimumAmount?: number;
  public handlingCharge?: number;
  public isActive?: boolean;
  public dateModified?: Date;
  public createdOrModifiedBy?: string;
  public rowver?: Date;

  constructor(
    id?: number,
    dateCreated?: Date,
    companyCode?: string,
    currencyCode?: string,
    countryCode?: string,
    orderMinimumAmount?: number,
    handlingCharge?: number,
    isActive?: boolean,
    dateModified?: Date,
    createdOrModifiedBy?: string,
    rowver?: Date
  ) {
    this.id = id;
    this.dateCreated = dateCreated;
    this.companyCode = companyCode;
    this.currencyCode = currencyCode;
    this.countryCode = countryCode;
    this.orderMinimumAmount = orderMinimumAmount;
    this.handlingCharge = handlingCharge;
    this.isActive = isActive;
    this.dateModified = dateModified;
    this.createdOrModifiedBy = createdOrModifiedBy;
    this.rowver = rowver;
  }
}
