import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { QueuedOrder } from './model/queued-order.model';
import {
  FilterSettingsModel,
  GridComponent,
} from '@syncfusion/ej2-angular-grids';
import { CacheService } from 'src/app/services/cache.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';

@Component({
  selector: 'app-eagle-creek',
  templateUrl: './eagle-creek.component.html',
  styleUrls: ['./eagle-creek.component.scss'],
})
export class EagleCreekComponent implements OnInit {
  @ViewChild('orderGrid') public orderGrid?: GridComponent;
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };

  public status: string;
  public count: number;
  public nextOperationFlag: boolean;
  public selectedRowData: QueuedOrder;
  public queuedOrders: QueuedOrder[];
  public dateRange: Date[] = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      new Date().getDate()
    ),
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ),
  ];

  constructor(
    private dataService: DataserviceService,
    private cache: CacheService
  ) {
    this.status = 'None';
    this.selectedRowData = new QueuedOrder();
    this.queuedOrders = [];
    this.nextOperationFlag = true;
    this.count = 0;
  }

  ngOnInit(): void {
    this.getOrders();
  }

  public getOrders(): void {
    this.dataService
      .getAll<QueuedOrder>('eaglecreek/GetOrders')
      .subscribe((data) => {
        this.queuedOrders = data;
      });
  }

  public onOrderMinimumGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }

  public onClickRetryButton(event: any): void {
    console.log(event);
    this.dataService
      .update<string>(`eaglecreek/SetRetryOrder/${event.shopifyOrderId}`)
      .subscribe((data) => {
        if (data == '1') {
          this.cache.showDialog.next(
            new NotificationMessage(
              DialogType.info,
              'Successfully Retried',
              `Order ${event.shopifyOrderId} was successfully retried.`
            )
          );

          this.getOrders();
        } else {
          this.cache.showDialog.next(
            new NotificationMessage(
              DialogType.error,
              'Error',
              `Order ${event.shopifyOrderId} cannot be retried.`
            )
          );
        }
      });
  }

  public async sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}
