import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import {
  ContextMenuComponent,
  MenuEventArgs,
  MenuItemModel,
} from '@syncfusion/ej2-angular-navigations';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { Router } from '@angular/router';
import { EmailDeliveryLog } from './model/email-delivery-log.model';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { EmailDeliveryLogDetails } from './model/email-delivery-log-details.model';
import { UserService } from 'src/app/services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  ConnectorConstraints,
  ConnectorModel,
  DiagramComponent,
  NodeModel,
  ShapeAnnotationModel,
} from '@syncfusion/ej2-angular-diagrams';
import { SalesRep } from './model/sales-rep.model';

@Component({
  selector: 'app-email-delivery-log',
  templateUrl: './email-delivery-log.component.html',
  styleUrls: ['./email-delivery-log.component.scss'],
})
export class EmailDeliveryLogComponent {
  @ViewChild('dialogEmailDeliveryLogDetails')
  dialogEmailDeliveryLogDetails!: DialogComponent;
  @ViewChild('dialogEmailAddress')
  dialogEmailAddress!: DialogComponent;
  public emailDeliveryLogList: EmailDeliveryLog[] = [];
  public selectedRowData: EmailDeliveryLog[] = [];
  public emailDeliveryLogDetails: EmailDeliveryLogDetails[] = [];
  public emailAddressList: { text: string }[] = [];
  @ViewChild('emailDeliveryLogGrid')
  public emailDeliveryLogGrid?: GridComponent;
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;

  public userSwitch: any;
  // Declared  Variables
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };
  public toolbarEmailDeliveryLogGrid?: ToolbarItems[];
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd HH:mm:ss' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public enableRowHover: boolean = false;
  constructor(
    private dataService: DataserviceService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.getEmailDeliveryLog();
    this.toolbarEmailDeliveryLogGrid = ['ColumnChooser', 'Search'];
  }
  public menuItems: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'View Details',
          id: 'viewDetails',
          iconCss: 'e-icons e-eye',
        },
        {
          text: 'Show Email',
          id: 'showEmail',
          iconCss: 'e-icons e-send-1',
        },
        {
          text: 'Download PDF',
          id: 'downloadPdf',
          iconCss: 'e-icons e-export-pdf',
        },
      ],
    },
  ];
  public onEmailDeliveryContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'viewDetails':
          this.showDetails(this.selectedRowData);
          break;
        case 'showEmail':
          this.showEmailDetails(this.selectedRowData);
          break;
        case 'downloadPdf':
          break;
        default:
      }
    }
  }

  public getEmailDeliveryLog() {
    this.dataService
      .getAll<EmailDeliveryLog>('EmailDeliveryLog/GetEmailDeliveryLog')
      .subscribe((data) => {
        this.emailDeliveryLogList = data;
      });
  }

  public onEmailDeliveryLogGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
  public showDetails(data: any): void {
    let id = data.id;
    this.dataService
      .getAll<EmailDeliveryLogDetails>(
        `EmailDeliveryLog/GetEmailDeliveryLogDetails/${id}`
      )
      .subscribe((data) => {
        this.emailDeliveryLogDetails = data;
      });
    this.dialogEmailDeliveryLogDetails.show();
  }
  public showEmailDetails(data: any): void {
    this.dialogEmailAddress.show();
    let orderNumber = data.orderNumber;
    this.dataService
      .getAll<any>(`EmailDeliveryLog/GetEmailAddress/${orderNumber}`)
      .subscribe((data) => {
        this.emailAddressList = data
          .filter((item) => item.emailAddress)
          .map((item) => ({
            text: item.emailAddress,
          }));
        console.log('showEmailDetails', this.emailAddressList);
      });
  }
  public hide(): void {
    this.dialogEmailDeliveryLogDetails.hide();
    this.dialogEmailAddress.hide();
  }
}
