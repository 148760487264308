import { default as data } from '../../package.json';

export const environment = {
  appVersion: data.version,
  production: false,
  apiUrl: 'https://usermanagementapi.cascadedesigns.com/api',
  authDomain: 'rex-login-prod01.cascadedesigns.com',
  authAudience: 'https://usermanagementapi.cascadedesigns.com',
  authClientId: '1v9FNAwWz1beh8o3wjQ1z1oZzyVMsT7n',
  authScope: 'openid profile',
  appInsights: {
    instrumentationKey:
      'InstrumentationKey=7387881f-6d86-46be-922d-71ba66961025;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/;ApplicationId=0bc9e76e-5a3b-4ff3-b24b-2f3b55429136',
  },
};
