import { Component, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {
  SidebarComponent,
  TreeViewComponent,
} from '@syncfusion/ej2-angular-navigations';

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss'],
})
export class HelpCenterComponent {
  constructor(private viewportScroller: ViewportScroller) {}
  // public scrollToAnchor(elementId: string): void {
  //   this.viewportScroller.scrollToAnchor(elementId);
  // }

  @ViewChild('sidebarTreeviewInstance')
  public sidebarTreeviewInstance?: SidebarComponent;
  @ViewChild('treeviewInstance')
  public treeviewInstance?: TreeViewComponent;
  public width: string = '290px';
  public enableDock: boolean = true;
  public dockSize: string = '44px';
  public mediaQuery: string = '(min-width: 600px)';
  public target: string = '.main-content';
  public selectedItem: any = null;
  public selectedContent: string = '';

  public data: Object[] = [
    {
      nodeId: '01',
      nodeText: 'User Management',
      iconCss: 'icon-circle-thin icon',
      nodeChild: [
        {
          nodeId: '01-01',
          nodeText: 'User Search',
        },
        {
          nodeId: '01-02',
          nodeText: 'Failed Logins',
        },
        {
          nodeId: '01-03',
          nodeText: 'Blocked Users',
        },
        {
          nodeId: '01-04',
          nodeText: 'Active Users',
        },
      ],
    },
    {
      nodeId: '02',
      nodeText: 'Portal Management',
      iconCss: 'icon-circle-thin icon',
      nodeChild: [
        {
          nodeId: '02-01',
          nodeText: 'Geo Location',
        },
        {
          nodeId: '02-02',
          nodeText: 'Server Metrics',
        },
        {
          nodeId: '02-03',
          nodeText: 'Event Log',
        },
        {
          nodeId: '02-04',
          nodeText: 'Order Minimum',
        },
        {
          nodeId: '02-05',
          nodeText: 'Order Modification Settings',
        },
        {
          nodeId: '02-06',
          nodeText: 'Report Configuration',
        },
        {
          nodeId: '02-07',
          nodeText: 'Email Delivery',
        },
      ],
    },
    {
      nodeId: '03',
      nodeText: 'Order Form Layout',
      iconCss: 'icon-circle-thin icon',
      nodeChild: [
        {
          nodeId: '03-01',
          nodeText: 'New Order Form',
        },
        {
          nodeId: '03-02',
          nodeText: 'Edit Order Form',
        },
        {
          nodeId: '03-03',
          nodeText: 'Save Order Form',
        },
        {
          nodeId: '03-04',
          nodeText: 'Publish Order Form',
        },
        {
          nodeId: '03-05',
          nodeText: 'Clone Order Form',
        },
        {
          nodeId: '03-06',
          nodeText: 'Quick Add',
        },
      ],
    },
    {
      nodeId: '04',
      nodeText: 'Shopify',
      iconCss: 'icon-circle-thin icon',
      nodeChild: [
        {
          nodeId: '04-01',
          nodeText: 'SalesForce to JDE',
        },
        {
          nodeId: '04-02',
          nodeText: 'Shopify 2',
        },
      ],
    },
    {
      nodeId: '05',
      nodeText: 'IPlanner',
      iconCss: 'icon-circle-thin icon',
      nodeChild: [
        {
          nodeId: '05-01',
          nodeText: 'New',
        },
        {
          nodeId: '05-02',
          nodeText: 'Edit',
        },
      ],
    },
    {
      nodeId: '06',
      nodeText: 'Eagle Creek',
      iconCss: 'icon-circle-thin icon',
      nodeChild: [
        {
          nodeId: '06-01',
          nodeText: 'New ',
        },
        {
          nodeId: '06-02',
          nodeText: 'Edit',
        },
      ],
    },
  ];
  public field: Object = {
    dataSource: this.data,
    id: 'nodeId',
    text: 'nodeText',
    child: 'nodeChild',
    iconCss: 'iconCss',
  };
  public contentMap: { [key: string]: string } = {
    '01-01': ` 
    <p>Search User by any column.</p> 
    <img src="../../../assets/img/userManagementDocumentation/searchUser.png" alt="User Search" width="100%">
    <p>Perform multiple actions on user serarch<p>
    <ul>
    <li>Edit User</li>
    <li>Delete User</li>
    <li>Edit Profile</li>
    <li>Password Reset</li>
    <li>Login Activity</li>
    </ul>
    
    <img src="../../../assets/img/userManagementDocumentation/search-user-action.png" alt="User Search" width="100%">
  `,
    '01-02': 'Content for Failed Logins',
    '01-03': 'Content for Blocked Users',
    '01-04': 'Content for Active Users',
    '02-01': 'Content for Geo Location',
    '02-02': 'Content for Server Metrics',
    '02-03': 'Content for Event Log',
    '02-04': 'Content for Order Minimum',
    '02-05': 'Content for Order Modification Settings',
    '02-06': 'Content for Report Configuration',
    '02-07': 'Content for Email Delivery',
    '03-01': 'Content for New Order Form',
    '03-02': 'Content for Edit Order Form',
    '03-03': 'Content for Save Order Form',
    '03-04': 'Content for Publish Order Form',
    '03-05': 'Content for Clone Order Form',
    '03-06': 'Content for Quick Add',
    '04-01': 'Content for SalesForce to JDE',
    '04-02': 'Content for Shopify 2',
    '05-01': 'Content for New',
    '05-02': 'Content for Edit',
    '06-01': 'Content for New ',
    '06-02': 'Content for Edit',
  };

  public onCreated(args: any) {
    (
      this.sidebarTreeviewInstance as SidebarComponent
    ).element.style.visibility = '';
  }
  public onClose(args: any) {
    (this.treeviewInstance as TreeViewComponent).collapseAll();
  }
  openClick() {
    if ((this.sidebarTreeviewInstance as SidebarComponent).isOpen) {
      (this.sidebarTreeviewInstance as SidebarComponent).hide();
      (this.treeviewInstance as TreeViewComponent).collapseAll();
    } else {
      (this.sidebarTreeviewInstance as SidebarComponent).show();
      (this.treeviewInstance as TreeViewComponent).expandAll();
    }
  }
  public onSidebarItemClick(item: any): void {
    console.log('Clicked item:', item);
    const nodeId = item.node.dataset?.uid;
    if (nodeId) {
      this.selectedItem = item.node.innerText;
      this.selectedContent = this.contentMap[nodeId];
    }
  }
}
