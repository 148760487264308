import { Component, ViewChild } from '@angular/core';
import { OrderModificationSettings } from './model/order-modification-settings.model';
import {
  ActionEventArgs,
  EditSettingsModel,
  FilterSettingsModel,
  GridComponent,
  ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { DataserviceService } from 'src/app/services/dataservice.service';
import { UserService } from 'src/app/services/user.service';
import { NotificationMessage } from 'src/app/shared/notification/model/notification-message.model';
import { DialogType } from 'src/app/shared/notification/model/dialog-type.enum';
import { CacheService } from 'src/app/services/cache.service';
import {
  MenuEventArgs,
  MenuItemModel,
} from '@syncfusion/ej2-angular-navigations';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-order-modification-settings',
  templateUrl: './order-modification-settings.component.html',
  styleUrls: ['./order-modification-settings.component.scss'],
})
export class OrderModificationSettingsComponent {
  public orderModificationSettingsList: OrderModificationSettings[] = [];
  @ViewChild('orderModificationSettingsGrid')
  public orderModificationSettingsGrid?: GridComponent;
  @ViewChild('ejDialogEditOrderModification')
  ejDialogEditOrderModification!: DialogComponent;
  public dateFormatOption = { type: 'date', format: 'yyyy-MMMM-dd' };
  public filterOption: FilterSettingsModel = { type: 'CheckBox' };
  public loadingIndicator = { indicatorType: 'Shimmer' };
  public toolbarOptions?: ToolbarItems[];
  public enableRowHover: boolean = false;
  editOrderModificationData: any;
  selectedRowData: any;

  constructor(
    private dataService: DataserviceService,
    private userService: UserService,
    private _cache: CacheService
  ) {}

  public menuItems: MenuItemModel[] = [
    {
      text: 'Action',
      items: [
        {
          text: 'Edit',
          id: 'edit',
          iconCss: 'e-icons e-edit',
        },
      ],
    },
  ];
  public onOrderModificationContextMenuSelect(event: MenuEventArgs): void {
    const selectedItem = event.item;
    if (this.selectedRowData) {
      switch (selectedItem.id) {
        case 'edit':
          this.editOrderModification(this.selectedRowData);
          break;
      }
    }
  }
  get numberOfDaysPriorToPickDate() {
    return this.editOrderModificationForm.get('numberOfDaysPriorToPickDate');
  }
  public editOrderModificationForm = new FormGroup({
    numberOfDaysPriorToPickDate: new FormControl('', [Validators.required]),
  });
  ngOnInit(): void {
    this.getOrderModificationSettings();
    this.toolbarOptions = ['ColumnChooser', 'Search'];
  }

  public getOrderModificationSettings() {
    this.dataService
      .getAll<OrderModificationSettings>(
        `OrderModificationSettings/GetOrderModificationSettings/${this.userService.userProfile.companyCode}`
      )
      .subscribe((data) => {
        this.orderModificationSettingsList = data;
      });
  }

  public editOrderModification(data: any) {
    this.editOrderModificationData = data;
    this.ejDialogEditOrderModification.show();
  }
  public onSaveEditOrderMinimumButtonClick() {
    let numberOfDaysPriorToPickDate = parseInt(
      this.editOrderModificationForm
        .get('numberOfDaysPriorToPickDate')
        ?.value?.toString() ?? '0'
    );
    if (numberOfDaysPriorToPickDate == 0) {
      numberOfDaysPriorToPickDate =
        this.editOrderModificationData.numberOfDaysPriorToPickDate;
    }
    const orderModificationSettings = new OrderModificationSettings(
      this.editOrderModificationData.id,
      new Date(),
      this.userService.userProfile.companyCode,
      this.editOrderModificationData.numberOfDaysPriorToPickDate,
      new Date(),
      this.userService.userProfile.name
    );
    this.dataService
      .update<any>(
        `OrderModificationSettings/UpdateOrderModificationSettings/${this.editOrderModificationData.id}/${this.userService.userProfile.companyCode}/${numberOfDaysPriorToPickDate}`,
        orderModificationSettings
      )
      .subscribe((response) => {
        this._cache.showDialog.next(
          new NotificationMessage(
            DialogType.info,
            'Update Order Modification Settings',
            `Order Modification Settings updated successfully`
          )
        );
        this.orderModificationSettingsGrid?.refresh();
        this.getOrderModificationSettings();
        this.ejDialogEditOrderModification.hide();
      });
  }
  public onEditOrderModificationDialogCloselButtonClick() {
    this.ejDialogEditOrderModification.hide();
  }
  public onOrderModificationGridRowSelected(selectedRowData: any): void {
    this.selectedRowData = selectedRowData;
  }
}
